<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="addUserModal"
      :title="type === 'add' ? `${$t('Add FC Receiving Agent')}`: `${$t('Edit FC Receiving Agent')}`"
      :buttons-hidden="true">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|alpha'" :label-placeholder="$t('First Name')" name="first name" v-model="firstName" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('first name')">{{ errors.first('first name') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|alpha'" :label-placeholder="$t('Last Name')" name="last name" v-model="lastName" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('last name')">{{ errors.first('last name') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input :disabled="type === 'edit'" v-validate="'required|email'" name="email" :label-placeholder="$t('Email')" v-model="email" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <template v-if="type === 'add'">
            <div class="vx-col w-1/2">
              <vs-input ref="password" type="password" v-validate="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }" :label-placeholder="$t('Password')" name="password" v-model="user.password" class="mt-10 w-full" />
              <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <vs-input type="password" v-validate="{ required: true, confirmed:'password' ,regex: /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/ }" :label-placeholder="$t('Confirm Password')" name="confirm password" v-model="user.confirm_password" class="mt-10 w-full" data-vv-as="password" />
              <span class="text-danger text-sm" v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
            </div>
          </template>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button v-if="type === 'add'" @click="addUser" class="active-btn btn">{{ $t('Add') }}</button>
          <button v-else @click="updateUser" class="active-btn btn">{{ $t('Save') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../../i18n/i18nData.js'

export default {
  props: ['addUserModal', 'type', 'data'],
  data () {
    return {
      user: {},
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  watch: {
    data () {
      if (this.type === 'edit') {
        this.firstName = this.data.name.split(' ')[0]
        this.lastName = this.data.name.split(' ')[1]
        this.email = this.data.email
      } else {
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.user = {} 
      }
      this.$validator.resume()
    }
  },
  methods: {
    updateUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isOnline) this.$emit('addUserModal', false)
          const userObj = {
            first_name: this.firstName,
            last_name: this.lastName
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/users/fc-receiving-agent/${this.data.user_id}/`, 'patch', userObj, true,               
            () => {
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['User'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    addUser () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isOnline) this.$emit('addUserModal', false)
          const userObj = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email.toLowerCase(),
            password: this.user.password
          }
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/users/fc-receiving-agent/', 'post', userObj, true,     
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['User'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.$emit('addUserModal', false)
              this.$emit('loadData')
            }
          )
        }
      })
    },
    closeModal () {
      this.$validator.pause()
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.$emit('addUserModal', false)
      this.$emit('loadData')
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss"
</style>